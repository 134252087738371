import config from '@/config';
import Web3 from 'web3'

let web3 = (window as any).web3

if (typeof web3 !== 'undefined') {
    web3 = new Web3()
}

export default class Web3Service {
    public static instance: Web3Service

    public static getInstance(): Web3Service {
        if (!this.instance) {
            this.instance = new Web3Service()
        }
        return this.instance
    }

    public connectedAccount = ''

    async getAccount(): Promise<string> {
        await web3.eth.currentProvider.enable()
        const accounts = await web3.eth.getAccounts()
        this.connectedAccount = accounts[0]

        return this.connectedAccount
    }

    public isConnected(): boolean {
      return !this.connectedAccount || this.connectedAccount === '';
    }

    async isConnectedToL1(): Promise<boolean> {
      const networkId = await web3.eth.net.getId();
      return networkId == config.l1NetworkId;
    }

    async isConnectedToL2(): Promise<boolean> {
      const networkId = await web3.eth.net.getId();
      return networkId == config.l2NetworkId;
    }

    getWeb3() {
        return web3
    }

    async getL1Web3(): Promise<Web3> {
      return (await this.isConnectedToL1()) ? this.getWeb3() : new Web3(config.l1Rpc);
    }

    async getL2Web3(): Promise<Web3> {
      return (await this.isConnectedToL2())? this.getWeb3() : new Web3(config.l2Rpc);
    }

    public async openTxScan(tx: string) {
        // TODO Scope etherscan link to current Provider
        window.open('https://rinkeby.etherscan.io/tx/' + tx)
    }
    public async openAddressScan(add: string) {
        // TODO Scope etherscan link to current Provider
        window.open('https://rinkeby.etherscan.io/address/' + add)
    }
}
