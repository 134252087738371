import axios from 'axios';
import Web3 from 'web3';
import { MaticPOSClient } from '@maticnetwork/maticjs'
import Network from '@maticnetwork/meta/network'

import Web3Service from '@/services/Web3Service'
import config from '@/config';

export default class MaticService {
    static MATIC_NETWORK_ID = 137;

    public static instance: MaticService

    public static getInstance(): MaticService {
        if (!this.instance) {
            this.instance = new MaticService()
        }
        return this.instance
    }

    public network: any;
    
    constructor() {
      const [network, version] = this.getNetworkVersion();
      this.network = new Network(network, version)
    }

    async createClient() {
      const web3 = Web3Service.getInstance().getWeb3();
      const networkId = await web3.eth.net.getId();
      const isL1 = networkId == config.l1NetworkId;
      const [network, version] = this.getNetworkVersion();
      const parentProvider = isL1 ? web3 : new Web3(config.l1Rpc);
      const maticProvider = isL1 ? new Web3(config.l2Rpc) : web3;
      return new MaticPOSClient({
        network, version,
        parentProvider,
        maticProvider
      })
    }

    getNetworkVersion() {
      const network = config.l2NetworkId == MaticService.MATIC_NETWORK_ID ? 'mainnet' : 'testnet';
      const version = config.l2NetworkId == MaticService.MATIC_NETWORK_ID ? 'v1' : 'mumbai';
      return [network, version];
    }

    public async isCheckpointReached(blockNumber: number): Promise<boolean> {
      const url = config.maticApiCheckpointURL.replace('$BLK_NB', blockNumber.toString());

      try {
        await axios.get(url);
        return true;
      } catch {
        return false;
      }
    }

    public async getDepositCurrentState(): Promise<number> {
      const l2Web3 = await Web3Service.getInstance().getL2Web3();
      const l2DepositContract = new l2Web3.eth.Contract(
        [
          {
            constant: true,
            inputs: [],
            name: "lastStateId",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
        ],
        "0x0000000000000000000000000000000000001001"
      );

      const lastStateId = await l2DepositContract.methods.lastStateId().call();
      return parseInt(lastStateId);
    }

    public async isDepositDone(txHash: string): Promise<boolean> {
      const l1Web3 = await Web3Service.getInstance().getL1Web3();
      const receipt = await l1Web3.eth.getTransactionReceipt(txHash);

      const depositStateId = Web3.utils.hexToNumberString(receipt.logs[3].topics[1]);
      const lastStateId = await this.getDepositCurrentState();

      return false;
    }
}
