export enum States {
    ON_L1 = 'ON_L1',
    CANDIDATE_FOR_L2 = 'CANDIDATE_FOR_L2',
    IN_TRANSIT_TO_L2 = 'IN_TRANSIT_TO_L2',
    ON_L2 = 'ON_L2',
    IN_TRANSIT_TO_L1 = 'IN_TRANSIT_TO_L1',
    READY_TO_REDEEM = 'READY_TO_REDEEM'
}

export const STATES = [
    { value: States.ON_L1, title: 'NFT L1' },
    { value: States.CANDIDATE_FOR_L2, title: 'NFT L2 CANDIDATE' },
    { value: States.IN_TRANSIT_TO_L2, title: 'NFT L1 BRIDGE TRANSIT' },
    { value: States.ON_L2, title: 'NFT L2' },
    { value: States.IN_TRANSIT_TO_L1, title: 'NFT L2 BRIDGE TRANSIT' },
    { value: States.READY_TO_REDEEM, title: 'NFT L1 REDEEMABLE' }
]
