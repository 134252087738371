import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "inline-block pb-2 text-gold text-3xl font-audiowide mb-2" }
const _hoisted_2 = { class: "flex flex-col space-y-2" }
const _hoisted_3 = { class: "flex flex-row items-center space-x-4" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = {
  key: 0,
  class: "text-red-500 text-sm font-sans mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, " Unlock " + _toDisplayString(_ctx.name), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.txHash) = $event)),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.unlock && _ctx.unlock(...args)), ["enter"])),
          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.error = '')),
          placeholder: 'burn ' + _ctx.name + ' token tx hash...',
          type: "text",
          class: _normalizeClass(["w-full h-10 text-black px-4 border border-black border-opacity-60 border-r-0 focus:outline-none", { 'border-red-500 text-red-500': _ctx.error.length }])
        }, null, 42, _hoisted_4), [
          [_vModelText, _ctx.txHash]
        ]),
        _createVNode(_component_Button, {
          onClick: _ctx.unlock,
          loading: _ctx.unlocking,
          class: "text-sm lg:text-base lg:w-auto whitespace-nowrap"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Unlock " + _toDisplayString(_ctx.name), 1)
          ]),
          _: 1
        }, 8, ["onClick", "loading"])
      ]),
      (_ctx.error && _ctx.error.length)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.error), 1))
        : _createCommentVNode("", true)
    ])
  ], 64))
}