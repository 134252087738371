import Web3 from 'web3'
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import NftItem from './NftItem.vue'
import NftModel from './NftModel'
import Web3Service from '@/services/Web3Service'
import MaticService from '@/services/MaticService'
import BridgeService from '@/services/web3/BridgeService'
import L1SpaceShipsService from '@/services/web3/L1SpaceShipsService'
import L2SpaceShipsService from '@/services/web3/L2SpaceShipsService'
import L1MemeService from '@/services/web3/L1MemeService'
import L2MemeService from '@/services/web3/L2MemeService'
import { States } from './states'
import Button from '@/components/Button/Button.vue'
import config from '@/config'

@Options({
    components: { NftItem, Button }
})
export default class NftList extends Vue {
    private config = config
    public nfts: NftModel[] = []

    @Prop({})
    public connectedAccount = ''

    @Prop({})
    public networkId = 0

    public isApprovedForAll = false;
    public loadingApproveForAll = false;

    public loadingNFTs = true;

    activeTab = 'ENTER'

    async mounted() {
      await this.loadNFTs()
    }

    get isOnCorrectNetwork() {
      return this.networkId === config.l1NetworkId || this.networkId === config.l2NetworkId;
    }

    @Watch('connectedAccount')
    @Watch("networkId")
    async loadNFTs() {
        if (!this.isOnCorrectNetwork) return;

        this.loadingNFTs = true;
        
        const me = await Web3Service.getInstance().getAccount()
        const depositContract = MaticService.getInstance().network.Main.POSContracts.ERC721PredicateProxy;

        /*
        const isApprovedForAll = await L1SpaceShipsService.getInstance().isApprovedForAllOfConnected(depositContract);
        const l1Ships = await L1SpaceShipsService.getInstance().tokensOf(me);
        const l2Ships = await L2SpaceShipsService.getInstance().tokensOf(me);
        const l1BridgeShips = await this.loadL1Pending();
        const l2BridgeShips = await this.loadL2Pending();
        const memes: NftModel[] = [];
        */

        const [
          isApprovedForAll,
          l1Ships, l2Ships,
          l1BridgeShips, l2BridgeShips,
          memes,
        ] = await Promise.all([
            L1SpaceShipsService.getInstance().isApprovedForAllOfConnected(depositContract),
            L1SpaceShipsService.getInstance().tokensOf(me),
            L2SpaceShipsService.getInstance().tokensOf(me),
            this.loadL1Pending(),
            this.loadL2Pending(),
            this.loadMemes(),
        ])

        this.isApprovedForAll = isApprovedForAll;

        const l1Allowances = await Promise.all(
            l1Ships.map(async id => ({
                id,
                approved: await BridgeService.getInstance().depositERC721Allowance(L1SpaceShipsService.getInstance().address, id.toString())
            }))
        )

        const l1Nfts = l1Allowances.map(({ id, approved }) => new NftModel(id.toString(), approved ? States.CANDIDATE_FOR_L2 : States.ON_L1))

        const l2Nfts = l2Ships.map(id => new NftModel(id.toString(), States.ON_L2))

        const allNfts = [...l1Nfts, ...l2Nfts, ...l1BridgeShips, ...l2BridgeShips, ...memes];
        allNfts.forEach(nft => {
          if (nft.isMeme) return; 
          nft.isApprovedForAll = this.isApprovedForAll;
        });

        this.nfts = allNfts;

        this.loadingNFTs = false;
    }

    async loadMemes() {
        const me = this.connectedAccount;
        const depositContract = MaticService.getInstance().network.Main.POSContracts.ERC1155PredicateProxy;

      const [isApprovedForAll, l1MemeModels, l2MemeModels] = await Promise.all([
        L1MemeService.getInstance().isApprovedForAllOfConnected(depositContract),
        L1MemeService.getInstance().tokensOf(me),
        L2MemeService.getInstance().tokensOf(me)
      ]);

      const l1arrays = l1MemeModels.map(
        ({ model, balance }) => Array(parseInt(balance)).fill(model)
      );
      const l2arrays = l2MemeModels.map(
        ({ model, balance }) => Array(parseInt(balance)).fill(model)
      );

      const l1MemeShips = Array.prototype.concat.apply([] as string[], l1arrays);
      const l2MemeShips = Array.prototype.concat.apply([] as string[], l2arrays);

      const l1State = isApprovedForAll ? States.CANDIDATE_FOR_L2 : States.ON_L1;
      return [
        ...l1MemeShips.map(id => new NftModel(id, l1State, true)),
        ...l2MemeShips.map(id => new NftModel(id, States.ON_L2, true)),
      ];
    }

    async approveForAll() {
        const depositContract = MaticService.getInstance().network.Main.POSContracts.ERC721PredicateProxy;

        this.loadingApproveForAll = true;

        try {
          await L1SpaceShipsService.getInstance().setApprovalForAll(depositContract, true);
          this.isApprovedForAll = true;
          this.nfts.forEach(nft => { nft.isApprovedForAll = this.isApprovedForAll });
        } catch (err) {
          console.error(err);
        }

        this.loadingApproveForAll = false;
    }

    async loadL1Pending(): Promise<NftModel[]> {
        const me = this.connectedAccount
        const l2Burns = await L2SpaceShipsService.getInstance().getBurns(me)

        const l2Txs = await Promise.all(
            l2Burns.map(async burn => {
                const id = burn.returnValues.tokenId
                const txHash = burn.transactionHash
                const isL1Synced = await MaticService.getInstance().isCheckpointReached(burn.blockNumber)

                if (!isL1Synced) return new NftModel(id, States.IN_TRANSIT_TO_L1)

                const processed = await BridgeService.getInstance().isERC721ExitProcessed(txHash)
                if (processed) return null

                const model = new NftModel(id, States.READY_TO_REDEEM)
                model.txHash = txHash
                return model
            })
        )

        const l2MemeBurns = await L2MemeService.getInstance().getBurns(me)

        const l2MemeTxs = await Promise.all(
            l2MemeBurns.map(async burn => {
                console.log('burn', burn);
                const id = burn.returnValues.id
                const txHash = burn.transactionHash
                const isL1Synced = await MaticService.getInstance().isCheckpointReached(burn.blockNumber)

                const amount = burn.returnValues.value;
                const ids = Array(parseInt(amount)).fill(id);
                if (!isL1Synced) return ids.map(id => new NftModel(id, States.IN_TRANSIT_TO_L1, true));

                try {
                  const processed = await BridgeService.getInstance().isERC1155ExitProcessed(txHash)
                  if (processed) return []
                } catch (err) {
                  console.error(txHash, err);
                  return [];
                }

                return ids.map(id => {
                  const model = new NftModel(id, States.READY_TO_REDEEM, true)
                  model.txHash = txHash
                  return model
                });
            })
        )
        return [
          ...l2Txs.filter(v => !!v) as NftModel[],
          ...Array.prototype.concat.apply([] as NftModel[], l2MemeTxs)
        ];
    }

    async loadL2Pending(): Promise<NftModel[]> {
        const depositContract = MaticService.getInstance().network.Main.POSContracts.ERC721PredicateProxy
        const depositState = await MaticService.getInstance().getDepositCurrentState()
        const me = this.connectedAccount

        const toL2Logs = await L1SpaceShipsService.getInstance().getTransfers(me, depositContract)
        const toL2Tx = await Promise.all(
            toL2Logs.map(async log => {
                const id = log.returnValues.tokenId
                const txHash = log.transactionHash
                const receipt = await (await Web3Service.getInstance().getL1Web3()).eth.getTransactionReceipt(txHash)
                const txDepositState = Web3.utils.hexToNumber(receipt.logs[3].topics[1])

                if (depositState >= txDepositState) return null

                return new NftModel(id, States.IN_TRANSIT_TO_L2)
            })
        )

        const memeDepositContract = MaticService.getInstance().network.Main.POSContracts.ERC1155PredicateProxy
        const toL2MemeLogs = await L1MemeService.getInstance().getTransfers(me, memeDepositContract)
        const toL2MemeTx = await Promise.all(
            toL2MemeLogs.map(async log => {
                const txHash = log.transactionHash
                const receipt = await (await Web3Service.getInstance().getL1Web3()).eth.getTransactionReceipt(txHash)
                const txDepositState = Web3.utils.hexToNumber(receipt.logs[2].topics[1])

                if (depositState >= txDepositState) return [];

                console.log(log.returnValues);
                const ships: string[] = [];
                for  (let i = 0; i < log.returnValues.ids.length; i++) {
                  const model = log.returnValues.ids[i];
                  const balance = log.returnValues.values[i];
                  const ids = Array(parseInt(balance)).fill(model);
                  ships.push(...ids); 
                }
                return ships.map(id => new NftModel(id, States.IN_TRANSIT_TO_L2, true));
            })
        )

        return [
          ...toL2Tx.filter(v => !!v) as NftModel[],
          ...Array.prototype.concat.apply([] as NftModel[], toL2MemeTx)
        ];
    }

    get shipsEnter() {
      return this.nfts.filter(this.isNotExit);
    }

    get shipsExit() {
      return this.nfts.filter(this.isExit);
    }

    isExit(nft: NftModel) {
        return nft.isOnL2
    }

    isNotExit(nft: NftModel) {
        return !this.isExit(nft)
    }
}
