import config from '@/config';
import MemeService from '@/services/web3/MemeService';
import Web3Service from '@/services/Web3Service';

export default class L2MemeService extends MemeService {
    public static instance: L2MemeService

    public static getInstance(): L2MemeService {
        if (!this.instance) {
            this.instance = new L2MemeService(config.l2MemeAddress)
        }
        return this.instance
    }

    constructor(address: string) {
      const getter = () => Web3Service.getInstance().getL2Web3();
      super(address, getter);
    }
}
