import config from '@/config';
import { States } from './ERC20States'
import TokenModel from './TokenModel';

export default class TokenTransaction {
    private config = config;
    public token!: TokenModel
    public txHash: string;
    public value = "0"
    public currentState!: States;

    constructor(token: TokenModel, txHash: string, value: string, state: States) {
      this.token = token;
      this.txHash = txHash;
      this.value = value;
      this.currentState = state;
    }

    get readyToRedeem() {
      return this.currentState === States.READY_TO_REDEEM;
    }

    get inTransitToL1() {
      return this.currentState === States.IN_TRANSIT_TO_L1;
    }

    get inTransitToL2() {
      return this.currentState === States.IN_TRANSIT_TO_L2;  
    }

    get displayedStatus() {
        switch (this.currentState) {
            case States.IN_TRANSIT_TO_L1:
                return 'Crossing the portal to '+config.l1NetworkLabel+' (~3 hours)'
            case States.IN_TRANSIT_TO_L2:
                return 'Crossing the portal to '+config.l2NetworkLabel+' (~5 min)'
            case States.READY_TO_REDEEM:
                return 'Locked on '+config.l1NetworkLabel
            default:
                return 'Unknown Status'
        }
    }
}
