import * as math from 'mathjs'
import Web3 from 'web3'
import BN from 'bn.js'
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import config from '@/config'
import Button from '@/components/Button/Button.vue'
import { States } from '@/components/ERC20/ERC20States'
import L1Form from '@/components/ERC20/L1Form/L1Form.vue'
import L2Form from '@/components/ERC20/L2Form/L2Form.vue'
import BridgeService from '@/services/web3/BridgeService'
import Web3Service from '@/services/Web3Service'
import MaticService from '@/services/MaticService'
import L1ERC20Service from '@/services/web3/L1ERC20Service'
import L2ERC20Service from '@/services/web3/L2ERC20Service'

import L1FormComponent from './L1Form/L1Form'
import L2FormComponent from './L2Form/L2Form'
import TokenModel from './TokenModel'
import TokenTransaction from './TokenTransaction'

@Options({
    components: { Button, L1Form, L2Form },
})
export default class ERC20List extends Vue {
    private config = config
    public loading = new Map<string, boolean>()
    public loadingPending = true
    public tokens: TokenModel[] = []
    public transactions: TokenTransaction[] = []

    @Prop({})
    public connectedAccount = ''

    @Prop({})
    public networkId = 0

    $refs!: {
        l1Form: L1FormComponent
        l2Form: L2FormComponent
    }

    activeTab = 'ENTER'

    formatBalance(value: string, decimals = 18, precision = 5) {
      const denominator = math.bignumber(10).pow(decimals)
      const v = math.bignumber(value).div(denominator)
      return math.format(v, { notation: 'fixed', precision })
    }

    userIsOnL1() {
        return this.networkId === config.l1NetworkId
    }

    userIsOnL2() {
        return this.networkId === config.l2NetworkId
    }

    async mounted() {
      try {
        await this.loadTokens()
      } catch (err) {
        console.error(err);
      }
    }

    @Watch('connectedAccount')
    async loadTokens() {
        this.loadingPending = true
        const tokens = config.bridgeTokens

        const tokensWithAllowance = await Promise.all(
            tokens.map(async token => {
                const [allowance, l1Balance, l2Balance] = await Promise.all([
                    this.loadAllowance(token),
                    this.loadL1Balance(token),
                    this.loadL2Balance(token)
                ])
                return {
                    ...token,
                    allowance,
                    l1Balance,
                    l2Balance
                }
            })
        )

        this.tokens = tokensWithAllowance.map(v => {
            const tokenModel = new TokenModel()
            tokenModel.symbol = v.symbol
            tokenModel.l1Address = v.l1Address
            tokenModel.l2Address = v.l2Address
            tokenModel.approved = v.allowance
            tokenModel.l1Balance = v.l1Balance
            tokenModel.l2Balance = v.l2Balance
            return tokenModel
        })

        const transactions = await Promise.all(this.tokens.map(token => this.loadTransactions(token)))
        this.transactions = ([] as TokenTransaction[]).concat(...transactions)
        this.loadingPending = false
    }

    async loadTransactions(token: TokenModel): Promise<TokenTransaction[]> {
        const [l1Transactions, l2Transactions] = await Promise.all([this.loadL1Transactions(token), this.loadL2Transactions(token)])
        return [...l1Transactions, ...l2Transactions]
    }

    async loadL2Transactions(token: TokenModel): Promise<TokenTransaction[]> {
        const me = this.connectedAccount
        const l2Burns = await L2ERC20Service.getInstance().getBurns(token.l2Address, me)

        const l2Txs = await Promise.all(
            l2Burns.map(async burn => {
                const txHash = burn.transactionHash
                const isL1Synced = await MaticService.getInstance().isCheckpointReached(burn.blockNumber)
                if (!isL1Synced) return new TokenTransaction(token, txHash, burn.returnValues.value, States.IN_TRANSIT_TO_L1)

                const processed = await BridgeService.getInstance().isERC20ExitProcessed(txHash)
                if (processed) return null

                return new TokenTransaction(token, txHash, burn.returnValues.value, States.READY_TO_REDEEM)
            })
        )
        return l2Txs.filter(v => !!v) as TokenTransaction[]
    }

    async loadL1Transactions(token: TokenModel): Promise<TokenTransaction[]> {
        // FIXME: HANDLE ETH.........................................
        if (!token.l1Address) return []

        const depositContract = MaticService.getInstance().network.Main.POSContracts.ERC20PredicateProxy
        const depositState = await MaticService.getInstance().getDepositCurrentState()
        const me = this.connectedAccount

        const toL2Logs = await L1ERC20Service.getInstance().getTransfers(token.l1Address, me, depositContract)
        const toL2Tx = await Promise.all(
            toL2Logs.map(async log => {
                const txHash = log.transactionHash
                const receipt = await (await Web3Service.getInstance().getL1Web3()).eth.getTransactionReceipt(txHash)
                const txDepositState = Web3.utils.hexToNumber(receipt.logs[3].topics[1])

                if (depositState >= txDepositState) return null

                return new TokenTransaction(token, txHash, log.returnValues.value, States.IN_TRANSIT_TO_L2)
            })
        )

        return toL2Tx.filter(v => !!v) as TokenTransaction[]
    }

    async loadAllowance(token: typeof config.bridgeTokens[0]): Promise<boolean> {
        // special case for ETH, allowance is not needed
        if (!token.l1Address) return true

        const allowance = await BridgeService.getInstance().depositERC20Allowance(token.l1Address)
        return !new BN(allowance).isZero()
    }

    async loadL1Balance(token: typeof config.bridgeTokens[0]): Promise<string> {
        // special case for ETH, return balance of account
        if (!token.l1Address) {
            const web3 = await Web3Service.getInstance().getL1Web3()
            return web3.eth.getBalance(this.connectedAccount)
        }
        return await L1ERC20Service.getInstance().balanceOf(token.l1Address, this.connectedAccount)
    }

    async loadL2Balance(token: typeof config.bridgeTokens[0]): Promise<string> {
        return await L2ERC20Service.getInstance().balanceOf(token.l2Address, this.connectedAccount)
    }

    // TODO update to refetch tokens and balance ?
    async onSentToL2(token: TokenModel, txHash: string, value: string) {
        console.log('sent-to-l2', txHash)
        const transaction = new TokenTransaction(token, txHash, value, States.IN_TRANSIT_TO_L2)
        this.transactions.unshift(transaction)
    }

    // TODO update: fetch api here and remove args
    async onWithdrawed(token: TokenModel, txHash: string, value: string) {
        const transaction = new TokenTransaction(token, txHash, value, States.IN_TRANSIT_TO_L1)
        this.transactions.unshift(transaction)
    }

    async unlock(transaction: TokenTransaction) {
        this.loading.set(transaction.txHash, true)

        await BridgeService.getInstance().exitERC20(transaction.txHash)

        const index = this.transactions.findIndex(e => e.txHash === transaction.txHash)
        this.transactions.splice(index, 1)

        const v = math.bignumber(transaction.token.l1Balance).add(transaction.value)
        transaction.token.l1Balance = math.format(v, { notation: 'fixed' })
        this.loading.set(transaction.txHash, false)
    }
}
