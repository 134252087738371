import config from '@/config';
import SpaceShipsService from '@/services/web3/SpaceShipsService';
import Web3Service from '@/services/Web3Service';

export default class L1SpaceShipsService extends SpaceShipsService {
    public static instance: L1SpaceShipsService

    public static getInstance(): L1SpaceShipsService {
        if (!this.instance) {
            this.instance = new L1SpaceShipsService(config.l1SpaceshipsAddress)
        }
        return this.instance
    }

    constructor(address: string) {
      const getter = () => Web3Service.getInstance().getL1Web3();
      super(address, getter);
    }
}
