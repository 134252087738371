import { Options, Vue } from 'vue-class-component'
import NftList from '@/components/NFT/NftList.vue'
import ERC20List from '@/components/ERC20/ERC20List.vue'
import Web3Service from '@/services/Web3Service';

import config from '@/config';

@Options({
    components: {
        NftList,
        ERC20List
    }
})
export default class Portal extends Vue {

  public connectedAccount = "";
  public networkId = 0;
  public config = config;

  public noMetamask = false;

  async mounted() {
    console.log('Portal.vue');
    await this.initWeb3();
  }

  async initWeb3(){
    
    if (typeof (window as any).ethereum === 'undefined') {
      this.noMetamask = true;
      return
    }

    const result = await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
    const web3 = Web3Service.getInstance().getWeb3();
    web3.setProvider((window as any).ethereum);

    await Promise.all([
      this.loadConnectedAccount(),
      this.loadNetworkId(),
    ]);


    this.subscribeToProviderEvents((window as any).ethereum);
    
  }

  async loadConnectedAccount() {
    this.connectedAccount = await Web3Service.getInstance().getAccount();
  }

  async loadNetworkId() {
    this.networkId = await Web3Service.getInstance().getWeb3().eth.net.getId();
    console.log(typeof this.networkId);
  }

  private subscribeToProviderEvents(provider: any) {
    provider.on("chainChanged", () => {
      this.loadNetworkId();
    });

    provider.on("accountsChanged", () => {
      this.loadConnectedAccount();
    });
  }
}
