import { Options, Vue } from 'vue-class-component'


export default class WaitingModal extends Vue {

	randomVideo(){

		const videos = [
		'https://www.youtube.com/watch?v=oU4Rk0NATNs&feature=youtu.be',
		'https://www.youtube.com/watch?v=0ZoSYsNADtY&feature=youtu.be',
		'https://www.youtube.com/watch?v=a3lcGnMhvsA&feature=youtu.be',
		'https://www.youtube.com/watch?v=prlIhY3e04k&feature=youtu.be',
		'https://www.youtube.com/watch?v=5J-Yysxa8Lo&feature=youtu.be',
		];
		
		window.open(videos[Math.floor(Math.random() * videos.length)], '_blank');
	}
}
