import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Portal from '@/pages/portal/Portal.vue'
import Unlock from '@/pages/portal/Unlock.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/unlock',
    name: 'Unlock',
    component: Unlock
  },
  {
    path: '/',
    name: 'Portal',
    component: Portal
  }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
