import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import Button from '@/components/Button/Button.vue'

@Options({
    components: { Button },
})
export default class UnlockForm extends Vue {
  @Prop({ required: true })
  public name!: string;

  @Prop({ required: true })
  public fn!: any;

  private txHash!: string;

  private error = '';
  
  private unlocking = false;

  async unlock() {
    this.unlocking = true;
    try {
      await this.fn(this.txHash);
      this.txHash = '';
    } catch (err) {
      this.error = err.message;
    }
    this.unlocking = false;
  }
}
