import Web3Service from '@/services/Web3Service'
import MaticService from '@/services/MaticService';

export default class BridgeService {
    public static instance: BridgeService

    public static getInstance(): BridgeService {
        if (!this.instance) {
            this.instance = new BridgeService()
        }
        return this.instance
    }

    public async approveERC20(token: string): Promise<string> {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];
      const client = await MaticService.getInstance().createClient();
      return client.approveMaxERC20ForDeposit(token, { from });
    }

    public async approveERC721(token: string, tokenId: string): Promise<string> {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];

      const client = await MaticService.getInstance().createClient();
      return client.approveERC721ForDeposit(token, tokenId, { from });
    }

    public async approveERC1155(token: string): Promise<string> {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];

      const client = await MaticService.getInstance().createClient();
      return client.approveERC1155ForDeposit(token, { from });
    }
    
    public async depositERC20Allowance(token: string): Promise<string> {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];
      const client = await MaticService.getInstance().createClient();
      return client.getERC20Allowance(from, token, { from });
    }

    public async depositERC721Allowance(token: string, tokenId: string): Promise<boolean> {
      const client = await MaticService.getInstance().createClient();
      return client.isApprovedERC721ForDeposit(token, tokenId);
    }

    public async depositERC721(token: string, tokenId: string) {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];
      const client = await MaticService.getInstance().createClient();
      return client.depositERC721ForUser(token, from, tokenId, { from });
    }

    public async depositERC1155(token: string, tokenId: string, amount = "1") {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];
      const client = await MaticService.getInstance().createClient();
      return client.depositSingleERC1155ForUser(token, from, tokenId, amount, "", { from });
    }

    public async depositERC20(token: string, amount: string) {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];
      const client = await MaticService.getInstance().createClient();
      return client.depositERC20ForUser(token, from, amount, { from });
    }

    public async depositETH(amount: string) {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];
      const client = await MaticService.getInstance().createClient();
      return client.depositEtherForUser(from, amount, { from });
    }

    public async withdrawERC20(token: string, amount: string) {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];
      const client = await MaticService.getInstance().createClient();
      return client.burnERC20(token, amount, { from });
    }

    public async withdrawERC721(token: string, tokenId: string) {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];
      const client = await MaticService.getInstance().createClient();
      return client.burnERC20(token, tokenId, { from });
    }

    public async withdrawERC1155(token: string, tokenId: string, amount = "1") {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];
      const client = await MaticService.getInstance().createClient();
      return client.burnSingleERC1155(token, tokenId, amount, { from });
    }

    public async exitERC721(burnTxHash: string) {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];
      const client = await MaticService.getInstance().createClient();
      return client.exitERC721(burnTxHash, { from });
    }

    public async exitERC1155(burnTxHash: string) {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];
      const client = await MaticService.getInstance().createClient();
      return client.exitSingleERC1155(burnTxHash, { from });
    }

    public async exitERC20(burnTxHash: string) {
      const web3 = Web3Service.getInstance().getWeb3();
      const from = (await web3.eth.getAccounts())[0];
      const client = await MaticService.getInstance().createClient();
      return client.exitERC20(burnTxHash, { from });
    }

    public async isERC20ExitProcessed(burnTxHash: string) {
      const client = await MaticService.getInstance().createClient();
      return client.isERC20ExitProcessed(burnTxHash);
    }

    public async isERC721ExitProcessed(burnTxHash: string) {
      const client = await MaticService.getInstance().createClient();
      return client.isERC721ExitProcessed(burnTxHash);
    }

    public async isERC1155ExitProcessed(burnTxHash: string) {
      const client = await MaticService.getInstance().createClient();
      return client.isSingleERC1155ExitProcessed(burnTxHash);
    }
}
