import config from '@/config';
import MemeService from '@/services/web3/MemeService';
import Web3Service from '@/services/Web3Service';

export default class L1MemeService extends MemeService {
    public static instance: L1MemeService

    public static getInstance(): L1MemeService {
        if (!this.instance) {
            this.instance = new L1MemeService(config.l1MemeAddress)
        }
        return this.instance
    }

    constructor(address: string) {
      const getter = () => Web3Service.getInstance().getL1Web3();
      super(address, getter);
    }
}
