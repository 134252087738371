import { States } from './states'

export default class NftModel {
    public id: string;
    public currentState = States.ON_L1
    public txHash!: string;
    public isApprovedForAll = false;
    public isMeme = false;

    constructor(id: string, state: States, isMeme = false) {
      this.id = id;
      this.currentState = state;
      this.isMeme = isMeme;
    }

    get isOnL1() {
        return this.currentState === States.ON_L1 && !(!this.isMeme && this.isApprovedForAll)
    }

    get isCandidateForL2() {
        return this.currentState === States.CANDIDATE_FOR_L2 || (this.currentState === States.ON_L1 && !this.isMeme && this.isApprovedForAll)
    }

    get isInTransitToL2() {
        return this.currentState === States.IN_TRANSIT_TO_L2
    }

    get isOnL2() {
        return this.currentState === States.ON_L2
    }

    get isInTransitToL1() {
        return this.currentState === States.IN_TRANSIT_TO_L1
    }

    get isReadyToRedeem() {
        return this.currentState === States.READY_TO_REDEEM
    }

    get canApprove() {
        return this.isOnL1
    }

    get canSend() {
        return this.isCandidateForL2
    }

    get canWithdraw() {
        return this.isOnL2
    }

    get canRelease() {
        return this.isReadyToRedeem
    }
}
