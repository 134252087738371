import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Button from '@/components/Button/Button.vue'
import config from '@/config';
import BridgeService from '@/services/web3/BridgeService';
import NFTService from '@/services/NFTService';
import MemeService from '@/services/MemeService';
import NftModel from './NftModel'
import { States } from './states'
import WaitingModal from '@/components/WaitingModal/WaitingModal.vue'

@Options({
    components: {
        Button,
        WaitingModal
    }
})
export default class NftItem extends Vue {
    private config = config;

    @Prop()
    public nft!: NftModel

    @Prop()
    public isApprovedForAll!: boolean

    public name = '';

    public image = '';

    @Prop()
    public networkId!: number;

    public loading = false

    public openWaitingModal = false

    async mounted() {
      if (this.nft.isMeme) {
        const metadata = await MemeService.getInstance().getMetadata(this.nft.id);
        this.name = metadata.data.name;
        this.image = metadata.data.image;
      } else {
        const metadata = await NFTService.getInstance().getMetadata(this.nft.id);
        this.name = metadata.data.name;
        this.image = config.shipImageUrl + '/' + this.nft.id + '.png';
      }
    }

    get isApproveBtnEnabled() {
        return this.userIsOnL1 && this.nft.canApprove
    }

    get isSendBtnEnabled() {
        return this.userIsOnL1 && this.nft.canSend
    }

    get isWithdrawBtnEnabled() {
        return this.userIsOnL2 && this.nft.canWithdraw
    }

    get isReleaseBtnEnabled() {
        return this.userIsOnL1 && this.nft.canRelease
    }

    get userIsOnL1() {
        return this.networkId === config.l1NetworkId
    }

    get userIsOnL2() {
        return this.networkId === config.l2NetworkId
    }

    get isOnWrongNetwork() {
        return !this.userIsOnL1 && !this.userIsOnL2;
    }

    async approve() {
        this.loading = true

        try {
          if (this.nft.isMeme) {
            await BridgeService.getInstance().approveERC1155(
              config.l1MemeAddress
            );
          } else {
            await BridgeService.getInstance().approveERC721(
              config.l1SpaceshipsAddress, this.nft.id
            );
          }
          this.nft.currentState = States.CANDIDATE_FOR_L2
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
    }

    async send() {
        this.loading = true

        try {
          if (this.nft.isMeme) {
            await BridgeService.getInstance().depositERC1155(
              config.l1MemeAddress,
              this.nft.id
            )
          } else {
            await BridgeService.getInstance().depositERC721(
              config.l1SpaceshipsAddress,
              this.nft.id
            )
          }
            this.nft.currentState = States.IN_TRANSIT_TO_L2
            this.openModal()

        } catch (err) {
            console.error(err);
        }
        this.loading = false
    }

    async withdraw() {
        this.loading = true

        try {
          if (this.nft.isMeme) {
            await BridgeService.getInstance().withdrawERC1155(
              config.l2MemeAddress, this.nft.id
            );
          } else {
            await BridgeService.getInstance().withdrawERC721(
              config.l2SpaceshipsAddress, this.nft.id
            );
          }
            this.nft.currentState = States.IN_TRANSIT_TO_L1
        } catch (err) {
            console.error(err);
        }
        this.loading = false
    }

    async release() {
        this.loading = true

        try {
            const txHash = this.nft.txHash ?? '';
            if (this.nft.isMeme) {
              await BridgeService.getInstance().exitERC1155(txHash);
            } else {
              await BridgeService.getInstance().exitERC721(txHash);
            }

            // TODO: We might need to check if NFT is not already allowed to
            // put it directly in CANDIDATE_FOR_L2 state
            this.nft.currentState = this.nft.isMeme ? States.CANDIDATE_FOR_L2 : States.ON_L1;
        } catch (err) {
            console.error(err);
        }
        this.loading = false
    }

    openModal(){
        this.openWaitingModal = true
    }
}
