import Web3Service from '@/services/Web3Service'

export default abstract class SpaceShipsService {
    private abi = [
        {
            inputs: [
                {
                    internalType: 'string',
                    name: 'name',
                    type: 'string'
                },
                {
                    internalType: 'string',
                    name: 'symbol',
                    type: 'string'
                },
                {
                    internalType: 'string',
                    name: 'baseURI',
                    type: 'string'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'approved',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'Approval',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'bool',
                    name: 'approved',
                    type: 'bool'
                }
            ],
            name: 'ApprovalForAll',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'Paused',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'toTokenId',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'childContract',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'childTokenId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'ReceivedChild',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    indexed: true,
                    internalType: 'bytes32',
                    name: 'previousAdminRole',
                    type: 'bytes32'
                },
                {
                    indexed: true,
                    internalType: 'bytes32',
                    name: 'newAdminRole',
                    type: 'bytes32'
                }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                }
            ],
            name: 'RoleGranted',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                }
            ],
            name: 'RoleRevoked',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'Transfer',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'fromTokenId',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'childContract',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256[]',
                    name: 'childTokenIds',
                    type: 'uint256[]'
                },
                {
                    indexed: false,
                    internalType: 'uint256[]',
                    name: 'amounts',
                    type: 'uint256[]'
                }
            ],
            name: 'TransferBatchChild',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'fromTokenId',
                    type: 'uint256'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'childContract',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'childTokenId',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                }
            ],
            name: 'TransferSingleChild',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'Unpaused',
            type: 'event'
        },
        {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'ID_TO_MODEL',
            outputs: [
                {
                    internalType: 'uint32',
                    name: '',
                    type: 'uint32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'MODEL_CREATOR_ROLE',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'WHITELIST_ADMIN_ROLE',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                }
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'baseURI',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokrnId',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'childContract',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'childTokenId',
                    type: 'uint256'
                }
            ],
            name: 'childBalance',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'childContractsFor',
            outputs: [
                {
                    internalType: 'address[]',
                    name: '',
                    type: 'address[]'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'childContract',
                    type: 'address'
                }
            ],
            name: 'childIdsForOn',
            outputs: [
                {
                    internalType: 'uint256[]',
                    name: '',
                    type: 'uint256[]'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'equipements',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'getApproved',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                }
            ],
            name: 'getRoleAdmin',
            outputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    internalType: 'uint256',
                    name: 'index',
                    type: 'uint256'
                }
            ],
            name: 'getRoleMember',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                }
            ],
            name: 'getRoleMemberCount',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'hasRole',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                }
            ],
            name: 'isApprovedForAll',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'name',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'nextId',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'uint256[]',
                    name: 'ids',
                    type: 'uint256[]'
                },
                {
                    internalType: 'uint256[]',
                    name: 'values',
                    type: 'uint256[]'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'onERC1155BatchReceived',
            outputs: [
                {
                    internalType: 'bytes4',
                    name: '',
                    type: 'bytes4'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'onERC1155Received',
            outputs: [
                {
                    internalType: 'bytes4',
                    name: '',
                    type: 'bytes4'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'ownerOf',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'paused',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: 'role',
                    type: 'bytes32'
                },
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address'
                }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'fromTokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'childContract',
                    type: 'address'
                },
                {
                    internalType: 'uint256[]',
                    name: 'childTokenIds',
                    type: 'uint256[]'
                },
                {
                    internalType: 'uint256[]',
                    name: 'amounts',
                    type: 'uint256[]'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'safeBatchTransferChildFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'fromTokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'childContract',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'childTokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'safeTransferChildFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: '_data',
                    type: 'bytes'
                }
            ],
            name: 'safeTransferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address'
                },
                {
                    internalType: 'bool',
                    name: 'approved',
                    type: 'bool'
                }
            ],
            name: 'setApprovalForAll',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'supply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'bytes4',
                    name: 'interfaceId',
                    type: 'bytes4'
                }
            ],
            name: 'supportsInterface',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'index',
                    type: 'uint256'
                }
            ],
            name: 'tokenByIndex',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'index',
                    type: 'uint256'
                }
            ],
            name: 'tokenOfOwnerByIndex',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'tokenURI',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'tokenId',
                    type: 'uint256'
                }
            ],
            name: 'transferFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function',
            constant: true
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'maxSupply',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'maxChild',
                    type: 'uint256'
                }
            ],
            name: 'newModel',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'model',
                    type: 'uint256'
                }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]

    public address: string;
    private web3Getter: () => Promise<any>;

    constructor(address: string, web3Getter: () => Promise<any>) {
      this.address = address;
      this.web3Getter = web3Getter;
    }

    public async contract() {
        const web3 = await this.web3Getter();
        return new web3.eth.Contract(this.abi, this.address)
    }

    public async balanceOf(address: string) {
        return (await this.contract()).methods.balanceOf(address).call()
    }

    public async tokenOfOwnerByIndex(address: string, index: number) {
        return (await this.contract()).methods.tokenOfOwnerByIndex(address, index).call()
    }

    public async getApproved(tokenId: string) {
        return (await this.contract()).methods.getApproved(tokenId).call()
    }

    public async approve(address: string, tokenId: string) {
        const web3 = Web3Service.getInstance().getWeb3()
        const account = (await web3.eth.getAccounts())[0]
        return (await this.contract()).methods.approve(address, tokenId).send({ from: account })
    }

    public async tokensOfConnected(): Promise<number[]> {
        const web3 = Web3Service.getInstance().getWeb3()
        const account = (await web3.eth.getAccounts())[0]
        const balance = await this.balanceOf(account)
        const tokens = []

        for (let i = 0; i < balance; i++) {
            tokens[i] = await this.tokenOfOwnerByIndex(account, i)
        }
        return tokens
    }

    public async tokensOf(address: string): Promise<number[]> {
        const balance = await this.balanceOf(address)
        const tokens = []
        for (let i = 0; i < balance; i++) {
            tokens[i] = await this.tokenOfOwnerByIndex(address, i)
        }
        return tokens
    }

    public async tokenURI(tokenId: number) {
        return (await this.contract()).methods.tokenURI(tokenId).call()
    }

    public async supply(model: number) {
        return (await this.contract()).methods.supply(model).call()
    }

    public async baseURI() {
        return (await this.contract()).methods.baseURI().call()
    }

    public async nextId(model: number) {
        return (await this.contract()).methods.nextId(model).call()
    }

    public async remaining(model: number) {
        const next = await this.nextId(model)
        const maxSupply = await this.supply(model)
        return maxSupply - next
    }

    public async spaceshipsOf(address: string): Promise<any> {
        const balance = Number(await this.balanceOf(address))
        const indexes = Array.from(Array(balance).keys())
        const contract = await this.contract();
        const spaceships: any = await Promise.all(
            Array.from(indexes).map(async index => {
                const tokenId = contract.methods.tokenOfOwnerByIndex(address, index).call()
                const image = contract.methods.tokenURI(tokenId).call()
                return { tokenId, image }
            })
        )
        return spaceships
    }

    public async isApprovedForAllOfConnected(operator: string)  {
        const web3 = Web3Service.getInstance().getWeb3()
        const account = (await web3.eth.getAccounts())[0]
        const contract = await this.contract();

        const result = await contract.methods.isApprovedForAll(account, operator).call();
        return result;
    }

    public async setApprovalForAll(operator: string, approved: boolean)  {
        const web3 = Web3Service.getInstance().getWeb3()
        const account = (await web3.eth.getAccounts())[0]
        const contract = await this.contract();

        return await contract.methods.setApprovalForAll(operator, approved).send({ from: account });
    }

    public async getTransfers(from: string, destination: string, fromBlock = ''): Promise<any[]> {
      if (fromBlock === '') {
        const web3 = await this.web3Getter();
        const currentBlock = await web3.eth.getBlockNumber();
        // 2 days before by default
        fromBlock = (currentBlock - (24*60*60*2)/3).toString();
      }
      const contract = await this.contract();
      return contract.getPastEvents("Transfer", {
        filter: {
          from,
          to: destination,
        },
        fromBlock,
        toBlock: 'latest',
      })
    }

    public async getBurns(from: string): Promise<any[]> {
      return this.getTransfers(from, "0x0000000000000000000000000000000000000000");
    }
}
