import config from '@/config';
import SpaceShipsService from '@/services/web3/SpaceShipsService';
import Web3Service from '@/services/Web3Service';

export default class L2SpaceShipsService extends SpaceShipsService {
    public static instance: L2SpaceShipsService

    public static getInstance(): L2SpaceShipsService {
        if (!this.instance) {
            this.instance = new L2SpaceShipsService(config.l2SpaceshipsAddress)
        }
        return this.instance
    }

    constructor(address: string) {
      const getter = () => Web3Service.getInstance().getL2Web3();
      super(address, getter);
    }
}
