import { States } from './ERC20States'

export default class TokenModel {
    public symbol = ''

    public l1Address : string|null = '';
    public l1Balance = ''
    public approved = false;

    public l2Address = ''
    public l2Balance = ''
}
