export default abstract class ERC20Service {
    private abi = [
        { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'owner', type: 'address' },
                { indexed: true, internalType: 'address', name: 'spender', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'Approval',
            type: 'event'
        },
        { anonymous: false, inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }], name: 'Paused', type: 'event' },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
                { indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32' },
                { indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32' }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
                { indexed: true, internalType: 'address', name: 'account', type: 'address' },
                { indexed: true, internalType: 'address', name: 'sender', type: 'address' }
            ],
            name: 'RoleGranted',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
                { indexed: true, internalType: 'address', name: 'account', type: 'address' },
                { indexed: true, internalType: 'address', name: 'sender', type: 'address' }
            ],
            name: 'RoleRevoked',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'from', type: 'address' },
                { indexed: true, internalType: 'address', name: 'to', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'Transfer',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
            name: 'Unpaused',
            type: 'event'
        },
        { inputs: [], name: 'DECIMALS', outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }], stateMutability: 'view', type: 'function' },
        {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'DOMAIN_SEPARATOR',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'INITIAL_SUPPLY',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
        },
        { inputs: [], name: 'NAME', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function' },
        {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'PERMIT_TYPEHASH',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
        },
        { inputs: [], name: 'SYMBOL', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function' },
        {
            inputs: [
                { internalType: 'address', name: 'owner', type: 'address' },
                { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'spender', type: 'address' },
                { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'account', type: 'address' },
                { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burnFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        { inputs: [], name: 'decimals', outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }], stateMutability: 'view', type: 'function' },
        {
            inputs: [
                { internalType: 'address', name: 'spender', type: 'address' },
                { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
            name: 'getRoleAdmin',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'bytes32', name: 'role', type: 'bytes32' },
                { internalType: 'uint256', name: 'index', type: 'uint256' }
            ],
            name: 'getRoleMember',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
            name: 'getRoleMemberCount',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'bytes32', name: 'role', type: 'bytes32' },
                { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'bytes32', name: 'role', type: 'bytes32' },
                { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'hasRole',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'spender', type: 'address' },
                { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        { inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function' },
        {
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'nonces',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        { inputs: [], name: 'pause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
        { inputs: [], name: 'paused', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function' },
        {
            inputs: [
                { internalType: 'address', name: 'holder', type: 'address' },
                { internalType: 'address', name: 'spender', type: 'address' },
                { internalType: 'uint256', name: 'nonce', type: 'uint256' },
                { internalType: 'uint256', name: 'expiry', type: 'uint256' },
                { internalType: 'bool', name: 'allowed', type: 'bool' },
                { internalType: 'uint8', name: 'v', type: 'uint8' },
                { internalType: 'bytes32', name: 'r', type: 'bytes32' },
                { internalType: 'bytes32', name: 's', type: 'bytes32' }
            ],
            name: 'permit',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'bytes32', name: 'role', type: 'bytes32' },
                { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'bytes32', name: 'role', type: 'bytes32' },
                { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        { inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function' },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'recipient', type: 'address' },
                { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'sender', type: 'address' },
                { internalType: 'address', name: 'recipient', type: 'address' },
                { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        { inputs: [], name: 'unpause', outputs: [], stateMutability: 'nonpayable', type: 'function' }
    ]

    private web3Getter: () => Promise<any>;

    constructor(getter: () => Promise<any>) {
      this.web3Getter = getter;
    }

    public async web3() {
        return await this.web3Getter();
    }

    public async contract(address: string) {
        const web3 = await this.web3();
        return new web3.eth.Contract(this.abi, address)
    }

    public async balanceOf(address: string, owner: string) {
        const contract = await this.contract(address);
        return await contract.methods.balanceOf(owner).call()
    }

    public async getTransfers(address: string, from: string, destination: string, fromBlock = ''): Promise<any[]> {
      if (fromBlock === '') {
        const web3 = await this.web3();
        const currentBlock = await web3.eth.getBlockNumber();
        // 2 days before by default
        fromBlock = (currentBlock - (24*60*60*2)/3).toString();
      }
      const contract = await this.contract(address);
      return contract.getPastEvents("Transfer", {
        filter: {
          from,
          to: destination,
        },
        fromBlock,
        toBlock: 'latest',
      })
    }

    public async getBurns(address: string, from: string): Promise<any[]> {
      return this.getTransfers(address, from, "0x0000000000000000000000000000000000000000");
    }
}
