import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export default class Button extends Vue {
    @Prop()
    public loading!: boolean

    @Prop({ default: true })
    public enabled!: boolean

    get disabled() {
        return this.loading || !this.enabled
    }
}
