<template>
  <!-- Ship Modal -->
  <div class="fixed z-50 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div @click="$emit('close')" class="fixed inset-0">
        <div class="absolute inset-0 bg-black bg-opacity-75"></div>
      </div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
        style="border: 1px solid #ffd700; box-shadow: 0 0 30px #ffd700"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div
          class="bg-white relative" 
          style="background-image: url('/wait.gif'); background-size: 250%; background-position: 30% 30%"
        >
          <div
            @click="$emit('close')"
            class="absolute top-0 right-0 m-4 cursor-pointer"
          >
            <svg
              class="w-5 h-5 fill-current text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M24 20.188l-8.315-8.209 8.2-8.282L20.188 0l-8.212 8.318L3.666.115 0 3.781l8.321 8.24-8.206 8.313L3.781 24l8.237-8.318 8.285 8.203z"
              />
            </svg>
          </div>
          <div
            class="flex-col sm:flex-row sm:flex sm:items-stretch bg-black bg-opacity-75"
          >
            <div class="sm:w-1/2 flex-shrink-0 flex items-start justify-center">
              <img class="" src="/wait.jpg" alt="Ship" />
            </div>
            <div
              class="sm:w-1/2 sm:pt-10 pb-4 px-4 sm:pr-8 text-center sm:text-left"
            >
              <h3 class="font-audiowide text-xl text-gold">You must wait 5 minutes</h3>
              <div class="mt-2">
                <p class="mb-6 text-white font-audiowide">Your ship is traveling to a new galaxy. During this time we offer you</p>
                <a target="_blank" href="https://medium.com/@MUSTCometh/d3e1878f93f2" class="mb-6  whitespace-no-wrap mt-12 text-lg  text-white border-b-4 rounded font-audiowide bg-gold hover:bg-yellow-400 text-black text-sm py-2 px-4 border-b-4 border-yellow-600 hover:cursor-pointer hover:border-yellow-500">
                    Read tutorial
                </a>

                <div class="pb-8"></div>
                
                <button @click="randomVideo" class="whitespace-no-wrap text-lg   text-white xl:text-lg  px-4 border-b-4 rounded font-audiowide bg-gold hover:bg-yellow-400 text-black text-sm px-4 border-b-4 border-yellow-600  hover:cursor-pointer hover:border-yellow-500">
                    Watch a random video
                </button>
                <div class="pb-4"></div>
              </div>
              <div class="mt-3">
                <p class="font-audiowide">
                  Thank you for your patience
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Ship Modal -->
</template>

<script src="./WaitingModal.ts"></script>
