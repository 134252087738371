import axios from 'axios'

export default class MemeService {
    public static instance: MemeService

    public static getInstance(): MemeService {
        if (!this.instance) {
            this.instance = new MemeService()
        }

        return this.instance
    }

    public async getMetadata(id: string) {
        return axios.get('https://api.dontbuymeme.com/memes/' + id)
    }
}
