import Web3Service from '@/services/Web3Service'
import ERC20Service from './ERC20Service';

export default class L2ERC20Service extends ERC20Service {
    public static instance: L2ERC20Service

    public static getInstance(): L2ERC20Service {
        if (!this.instance) {
            this.instance = new L2ERC20Service()
        }
        return this.instance
    }

    constructor() {
      const getter = () => Web3Service.getInstance().getL2Web3();
      super(getter);
    }
}
