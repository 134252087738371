const l1NetworkId = parseInt(process.env.VUE_APP_L1_NETWORK_ID || '5', 10);
const l2NetworkId = parseInt(process.env.VUE_APP_L2_NETWORK_ID || '80001', 10);

const network = l2NetworkId === 137 ? 'matic' : 'mumbai';
const maticApiCheckpointURL = `https://apis.matic.network/api/v1/${network}/block-included/$BLK_NB`;

const l1SpiceAddress = process.env.VUE_APP_SPICE_ADDRESS || '0x59ea29DFb8cBaBB745B96b67fe2Dc28cfB41Ecf0';
const l2SpiceAddress = process.env.VUE_APP_L2_SPICE_ADDRESS || '0xCd47A4992BeC3dd1851a448d512800dEC7ba9e9e';


export default {
  l1SpiceAddress,
	l1SpaceshipsAddress: process.env.VUE_APP_SPACESHIPS_ADDRESS || '0xe2E5094fd6e7AeE2046C08b98DD7e870d766194f',
  l1MemeAddress: process.env.VUE_APP_L1_MEME_ADDRESS || '0x2e3ef7931f2d0e4a7da3dea950ff3f19269d9063',

  l1Rpc: process.env.VUE_APP_L1_RPC_URL || "https://goerli.infura.io/v3/66ef75d39b1445d6866ac160fe3ea397",
	l1NetworkId,
	l1NetworkLabel: process.env.VUE_APP_L1_NETWORK_LABEL || "Goerli",
	l1BlockExplorer: process.env.VUE_APP_L1_BLOCKEXPLORER || "https://goerli.etherscan.io",
	l1Web3ModalNetwork: process.env.VUE_APP_L1_WEB3_MODAL_NETWORK || "goerli",

  l2SpiceAddress,
  l2SpaceshipsAddress: process.env.VUE_APP_L2_SPACESHIPS_ADDRESS || '0xe2E5094fd6e7AeE2046C08b98DD7e870d766194f',
  l2MemeAddress: process.env.VUE_APP_L2_MEME_ADDRESS || '0xA07e45A987F19E25176c877d98388878622623FA',
  l2Rpc: process.env.VUE_APP_L2_RPC_URL || "https://rpc-mumbai.matic.today",
	l2NetworkId,
	l2NetworkLabel: process.env.VUE_APP_L2_NETWORK_LABEL || "Matic Mumbai",
	l2BlockExplorer: process.env.VUE_APP_L2_BLOCKEXPLORER || "https://explorer-mumbai.maticvigil.com/",

	infuraId: process.env.VUE_APP_INFURA_ID || "2e297f45a4fd4a84b371c9fe432e2e6f",
	shipImageUrl:process.env.VUE_APP_SHIP_IMAGE_URL || "https://images.service.cometh.io",

  bridgeTokens: [
    { symbol: 'MUST', l1Address: l1SpiceAddress, l2Address: l2SpiceAddress },
    /* FIXME: this DAI is useless, only for testing purposes */
    /*
       DAI, SUSHI, LINK, MEME, GHST
    */
  ],

  maticApiCheckpointURL,
}
