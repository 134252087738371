import * as math from 'mathjs';
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import BridgeService from '@/services/web3/BridgeService';
import Button from '@/components/Button/Button.vue'
import config from '@/config';

import TokenModel from '../TokenModel'

@Options({
    components: { Button },
    emits: ['sent-to-l2']
})
export default class L1Form extends Vue {
    @Prop()
    public networkId!: number

    @Prop()
    public tokens!: TokenModel[];

    public selectedToken = 0;

    public isApprovingL1 = false
    public tokenAmount = "0"
    public isSendingToL2 = false

    public error = ''

    public config = config

    get canSendToL2() {
        return Number(this.tokenAmount) > 0 && this.correctNetwork
    }

    get correctNetwork() {
        return this.networkId === config.l1NetworkId;
    }

    formatBalance(value: string, decimals = 18, precision = 5) {
      const denominator = math.bignumber(10).pow(decimals);
      const v = math.bignumber(value).div(denominator);
      return math.format(v, { notation: "fixed", precision });
    }

    setMax() {
      if (!this.tokens || !this.tokens.length) return;

      const decimals = math.bignumber(10).pow(18);
      const amount = math.bignumber(this.tokens[this.selectedToken].l1Balance).div(decimals);

      this.tokenAmount = math.format(amount, { notation: 'fixed' });
    }

    async approveSelectedTokenOnL1() {

        const token = this.tokens[this.selectedToken];

        if (!token.l1Address) return;
        this.isApprovingL1 = true
        await BridgeService.getInstance().approveERC20(token.l1Address);
        token.approved = true;
        this.isApprovingL1 = false
    }

    async sendToL2() {
        if (Number(this.tokenAmount) <= 0) {
            this.error = 'Value should be > 0'
            return
        }

        const token = this.tokens[this.selectedToken];
        const decimals = math.bignumber(10).pow(18);
        const quantity = math.bignumber(this.tokenAmount).mul(decimals);
        const limit = math.bignumber(token.l1Balance);
        if (quantity.gt(limit)) {
            this.error = 'Not enough in your balance'
            return
        }

        const amount = math.format(quantity, { notation: 'fixed' });

        this.isSendingToL2 = true
        let result;
        if (token.l1Address) {
          result = await BridgeService.getInstance().depositERC20(token.l1Address, amount);
        } else {
          result = await BridgeService.getInstance().depositETH(amount);
        }

        token.l1Balance = math.format(limit.sub(quantity), { notation: 'fixed' });
        this.$emit('sent-to-l2', token, result.transactionHash, quantity)
        this.isSendingToL2 = false
        this.tokenAmount = "0"

    }
}
