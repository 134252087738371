import * as math from 'mathjs';
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import Button from '@/components/Button/Button.vue'
import BridgeService from '@/services/web3/BridgeService';
import config from '@/config';

import TokenModel from '../TokenModel'

@Options({
    components: { Button },
    emits: ['withdrawed']
})
export default class L2Form extends Vue {
    @Prop()
    public networkId!: number

    @Prop()
    public tokens!: TokenModel[];

    public selectedToken = 0;

    // public isApproving = false
    public tokenAmount = "0"
    public isWithdrawing = false

    public error = ''

    public config = config

    get canWithdraw() {
        return Number(this.tokenAmount) > 0 && this.correctNetwork
    }

    get correctNetwork() {
        return this.networkId === config.l2NetworkId;
    }

    formatBalance(value: string, decimals = 18, precision = 5) {
      const denominator = math.bignumber(10).pow(decimals);
      const v = math.bignumber(value).div(denominator);
      return math.format(v, { notation: "fixed", precision });
    }

    setMax() {
      if (!this.tokens || !this.tokens.length) return;

      const decimals = math.bignumber(10).pow(18);
      const amount = math.bignumber(this.tokens[this.selectedToken].l2Balance).div(decimals);

      this.tokenAmount = math.format(amount, { notation: 'fixed' });
    }

    async withdraw() {
        if (Number(this.tokenAmount) <= 0) {
            this.error = 'Value should be > 0'
            return
        }

        const token = this.tokens[this.selectedToken];
        const decimals = math.bignumber(10).pow(18);
        const quantity = math.bignumber(this.tokenAmount).mul(decimals);
        const limit = math.bignumber(token.l2Balance);
        if (quantity.gt(limit)) {
            this.error = 'Not enough in your balance'
            return
        }

        this.isWithdrawing = true
        const amount = math.format(quantity, { notation: 'fixed' });
        const result = await BridgeService.getInstance().withdrawERC20(token.l2Address, amount);

        token.l2Balance = math.format(limit.sub(quantity), { notation: 'fixed' });
        this.$emit('withdrawed', token, result.transactionHash, quantity)
        this.tokenAmount = "0"
        this.isWithdrawing = false
    }
}
