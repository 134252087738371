import Web3Service from '@/services/Web3Service'
import ERC20Service from './ERC20Service';

export default class L1ERC20Service extends ERC20Service {
    public static instance: L1ERC20Service

    public static getInstance(): L1ERC20Service {
        if (!this.instance) {
            this.instance = new L1ERC20Service()
        }
        return this.instance
    }

    constructor() {
      const getter = () => Web3Service.getInstance().getL1Web3();
      super(getter);
    }
}
